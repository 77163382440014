
  import { Component, Vue } from 'vue-property-decorator'

  @Component
  export default class GroupScheduleBtn extends Vue {
  // Methods
    goToSchedule = () => {
      this.$router.push({
        name: 'group-schedule',
      })
    }

  // Getters

  // Watchers
  }


  import { Component, Vue, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import ActiveUser from '@/components/ActiveUser.vue'
  import List from '@/layouts/List.vue'
  import { Link } from '@/store/app/state'
  import ScheduleBtn from '@/components/app/ScheduleBtn.vue'
  import GroupScheduleBtn from '@/components/app/GroupScheduleBtn.vue'
  import { ROLES_DASHBOARD_GROUP_ALLOWED, RoleSysop } from '@/store/user/state'

@Component({
  components: {
    ActiveUser,
    ScheduleBtn,
    GroupScheduleBtn,
    DrawerHeader: () =>
      import(
        /* webpackChunkName: "default-drawer-header" */
        './widgets/DrawerHeader.vue'
        ),
    List,
  },
  methods: {
    ...mapActions('app', ['setMini', 'toggleDrawer']),
    ...mapMutations('app', ['setMenu']),
  },
  computed: {
    ...mapGetters('app', ['isMini', 'getStaticMenu', 'drawerOpened', 'isMobile']),
    ...mapGetters('resources', ['links']),
    ...mapGetters('user', ['roles']),
  },
})
  export default class Drawer extends Vue {
  isMobile!: boolean;

  drawerOpened!: boolean;
  toggleDrawer!: (payload?: boolean) => void

  isMini!: boolean;
  setMini!: (payload: boolean) => void;

  setMenu!: (payload: Link) => void;

  getStaticMenu!: Array<Link>;
  search = '';

  get isDark (): boolean {
    return this.$vuetify.theme.dark
  }

  goWhatsapp () {
    const protocol = window.location.protocol
    const host = window.location.host
    const path = this.$router.currentRoute.path

    const url = `${protocol}//${host}${path}`
    window.open(`https://wa.me/56977795106?text=Hola%20Cesar,%20estoy%20en%20${url}%20y%20tengo%20una%20duda%0A%0A`, '_blank')
  }

  @Watch('roles', { immediate: true, deep: true })
  allowSchedule (val) {
    if (!val?.length) {
      return
    }
    if (val.some(role => ROLES_DASHBOARD_GROUP_ALLOWED[role.slug]) && !this.getStaticMenu.map(item => item.id).includes(2)) {
      this.setMenu(
        {
          id: 2,
          name: 'Agenda',
          icon: 'mdi-calendar-month',
          route: { name: 'schedule' },
        },
      )
    }
    if (val.some(role => RoleSysop[role.slug]) && !this.getStaticMenu.map(item => item.id).includes(3)) {
      this.setMenu(
        {
          id: 3,
          name: 'Estatus usuario',
          icon: 'mdi-account-clock',
          route: { name: 'sysop-user' },
        },
      )
    }
  }
  }


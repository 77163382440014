
  import { Component, Vue } from 'vue-property-decorator'
  import Drawer from '@/layouts/Drawer.vue'
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { ComponentCategory } from '@/entities/vehicle'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'
  import { Role } from '@/store/user/models'
  import { ROLES_INSPECTION_ALLOWED } from '@/store/user/state'

@Component({
  components: {
    AppBar: () =>
      import(
        /* webpackChunkName: "default-app-bar" */
        './AppBar.vue'
        ),
    Drawer,
    AppView: () =>
      import(
        /* webpackChunkName: "default-view" */
        './Views.vue'
        ),
    ViewFooter: () =>
      import(
        /* webpackChunkName: "default-footer" */
        './Footer.vue'
        ),
  },
  methods: {
    ...mapActions('app', ['setMini']),
    ...mapMutations('resources', ['setCategories']),
    ...mapActions('resources/form', ['fetchData']),
  },
  computed: {
    ...mapGetters('user', ['roles']),
  },
})
  export default class Index extends Vue {
  setCategories!: (payload: Array<ComponentCategory>) => void
  setMini!: (payload: boolean) => void;
  roles!: Array<Role>;

  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  get binds () {
    return {}
  }

  get bar () {
    return this.binds
  }

  get drawer () {
    return this.binds
  }

  get view () {
    return this.binds
  }

  get footer () {
    return this.binds
  }

  async mounted () {
    const { roles } = this

    const allow = roles.some(role => ROLES_INSPECTION_ALLOWED[role.name])
    if (allow) {
      const categories = await this.fetchData({
        query: { name: 'find', model: 'ComponentCategory' },
        filter: { components: { inspection_components: { inspection_parameters: { id: { _is_null: false } } } } },
      })

      this.setCategories(categories)
    }
  }
  }
